import React from "react";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";

import "../assets/css/footer.scss";
import logoAnotherHireDark from "../assets/images/logoAnotherHire_dark.svg";

const Footer = () => {

    let copyrightYear = new Date().getFullYear();

    return (
        <div className="footer-container mt-auto"> 
            <div className="footer-logo-container">
                <Link to="/">
                    <img className="footer-logo" src={logoAnotherHireDark} alt={"logo"} />
                </Link>
            </div>
            <div className="footer-divider"></div>
            <div className="footer-lower-container">
                <div className="footer-copyright-text">
                    © {copyrightYear}&nbsp;&nbsp;<a href="https://another.biz" target="_blank" rel="noreferrer">ANOTHER LLC</a>
                </div>
                <div className="footer-social-icon-container">
                    <a className="footer-social-icon footer-social-icon-me" href="https://instagram.com/another.biz" target="_blank" rel="noreferrer"><Icon icon="fa-brands:instagram" alt="instagram" /></a>
                    <a className="footer-social-icon footer-social-icon-me" href="https://twitter.com/another_biz" target="_blank" rel="noreferrer"><Icon icon="fa-brands:twitter" alt="twitter" /></a>
                    <a className="footer-social-icon footer-social-icon-me" href="https://facebook.com/another.biz" target="_blank" rel="noreferrer"><Icon icon="fa-brands:facebook-f" alt="instagram" /></a>
                    <a className="footer-social-icon" href="https://linkedin.com/company/another-biz" target="_blank" rel="noreferrer"><Icon icon="fa-brands:linkedin" alt="linkedin" /></a>
                </div>
            </div>
        </div>
    );
};

export default Footer;