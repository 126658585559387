import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Icon } from "@iconify/react";

import "../assets/css/main_search.scss";
import "../assets/css/_forms.scss";

const MainSearch = () => {

    const { register, handleSubmit } = useForm();

    const onSubmit = async (data) => {

        try {
            let jobTitle = data.job_title;
            let location = data.location;

            let externalURL = `${process.env.REACT_APP_HIRE_APP_URL}/?search=${jobTitle}&location=${location}&environment=&job_type=&industry=&industry_category=&date_posted=&offset=0&count=25`;
            window.location.replace(externalURL);

        } catch (e) {
            console.error(e);
        }
    };

    return (
        <div className="main-search-container">
            <span className="main-search-heading">LET'S GET TO WORK.</span>
            <form className="main-search-form" onSubmit={handleSubmit(onSubmit)}>
                <div className="main-search-form-input-field-container">
                    <label><strong>What</strong></label>
                    <input 
                        type="text"
                        {...register("job_title")}
                        placeholder="Job Title or Company"
                    />
                </div>
                <div className="main-search-form-input-field-container">  
                    <label><strong>Where</strong></label>
                    <input 
                        type="text"
                        {...register("location")}
                        placeholder="City, State Abbrev or Zip Code"
                    />
                </div>
                <div className="main-search-form-button-container">
                    <button 
                        type="submit" 
                        className="form-submit-button" >
                        Let's Go
                    </button>
                </div>
            </form>
            <div className="main-search-divider">&nbsp;</div>
        </div>
    );
};

export default MainSearch;