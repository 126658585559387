import React from "react";

import "../assets/css/main_action.scss";

const MainCallToAction = () => {


    return (
        <div className="main-content-container">
            <div className="main-content-inner-container">
                <div className="main-action-title-container">
                    <div className="main-action-title-divider">&nbsp;</div>
                    <div className="main-action-title">Get the job done with AnotherHire™</div>
                    <h2 className="mb-4">Finally! A hiring platform that brings a straightforward approach to the recruitment process - all for just $49 per job post.</h2>
                </div>
                <a href={`${process.env.REACT_APP_HIRE_APP_URL}/job-post/`} className="form-submit-button main-action-job-post-button">
                    Post a Job
                </a>
            </div>
        </div>
    );
};

export default MainCallToAction;