import React from "react";
import { Link } from "react-router-dom";

import "../assets/css/header.scss";
import logo from "../assets/images/logoAnotherHire_black.svg";

const Header = () => {

    return (
        <div className="header-container">
            <div className="header-logo-container">
                <Link to="/">
                    <img className="header-logo" src={logo} alt={"logo"} />
                </Link>
            </div>
            <a href={`${process.env.REACT_APP_HIRE_APP_URL}/login/`} className="form-submit-button header-job-post-button">
                Post a Job
            </a>
        </div>
    );
};

export default Header;