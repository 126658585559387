// Main page loader
import React from "react";

import "../assets/css/main.scss";

import MainSearch from "./main_search";
import MainFeatures from "./main_feature";
import MainTestimonials from "./main_testimonials";
import MainWhy from "./main_why";
import MainAction from "./main_action";

const Main = () => {

    return (
        <div className="container-wide">
            <div className="main-content-container">
                <MainSearch />
                <MainFeatures />
                <MainTestimonials />
                <MainWhy />
                <MainAction />
            </div>
        </div>
    );
};

export default Main;