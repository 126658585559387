import React from "react";

import "../assets/css/main_why.scss";

import iconCandidate from "../assets/images/icon_candidate.svg"
import iconCustom from "../assets/images/icon_custom.svg"
import iconDollar from "../assets/images/icon_dollar.svg"

const MainWhy = () => {

    return (
        <div className="main-content-inner-container">
            <div className="main-why-lead-container">
                <h1 className="main-why-title">Why AnotherHire™?</h1>
                <h2 className="mb-5">Because you have a job to do. And, when you get next-level features like Candidate Management and Custom Branded Job Boards for just $49/job post; AnotherHire™ can help you get the job done right.</h2>
            </div>
            <div className="main-why-card-container">
                <div className="main-why-card">
                    <img src={iconDollar} loading="lazy" alt="$49 Job Posts" />
                    <div className="main-why-reason-title">$49 Job Posts</div>
                    <div className="main-why-reason-body">
                        We know what you're paying to post your jobs right now and it isn't pretty.
                    </div>
                </div>
                <div className="main-why-card">
                    <img src={iconCandidate} loading="lazy" alt="Candidate Management" />
                    <div className="main-why-reason-title">Candidate Management</div>
                    <div className="main-why-reason-body">
                        Track your candidates throughout your hiring process directly in AnotherHire™ with an intuitive applicant management dashboard.
                    </div>
                </div>
                <div className="main-why-card">
                    <img src={iconCustom} loading="lazy" alt="Custom Job Boards" />
                    <div className="main-why-reason-title">Custom Job Boards</div>
                    <div className="main-why-reason-body">
                        Create your own branded job board and invite others to post jobs within your association, a job fair, special event, or specific career fields.
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainWhy;