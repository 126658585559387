import React from "react";

import "../assets/css/main_testimonial.scss";

import imgJulieJones from "../assets/images/imgJulieJones.jpeg"
import imgJennieJamrock from "../assets/images/imgJennieJamrock.jpeg";

const MainTestimonials = () => {

    return (
        <div className="main-content-inner-container">
            <div className="main-testimonial-card-container">
                <div className="main-testimonial-card">
                    <div className="main-testimonial-card-text">
                        "Our organization is excited about the simplicity, transparency, and cost! This is a win for our organization. As a private non-profit, this will bring solutions to budget constraints and that overwhelming feeling you get trying to manage candidates when job boards combine with social media platforms."
                    </div>
                    <div className="main-testimonial-card-ref-container">
                        <div className="main-testimonial-card-ref-headshot">
                            <img src={imgJulieJones} alt="Julie Jones Testimonial" />
                        </div>
                        <div className="main-testimonial-card-ref-text">
                            <span>Julie J.</span>
                            Human Resources Director<br />
                            Jones Farms
                        </div>
                    </div>
                </div>
                <div className="main-testimonial-card">
                    <div className="main-testimonial-card-text">
                        "As a seasoned HR Leader, I could see small businesses and Associations signing on to use the custom job boards feature for their membership and save tens of thousands per year, rather than paying expensive software licensing fees to host job boards on their websites. What a win for small businesses and associations!"
                    </div>
                    <div className="main-testimonial-card-ref-container">
                        <div className="main-testimonial-card-ref-headshot">
                            <img src={imgJennieJamrock} alt="Jennie Jamrock Testimonial" />
                        </div>
                        <div className="main-testimonial-card-ref-text">
                            <span>Jennie J.</span>
                            Founder at <a href="https://jamrock3c.com" target="_blank" rel="noreferrer">Jamrock3C</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainTestimonials;