import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ScrollToTop from "./global/scroll-to-top";

import Header from "./header";
import Main from "./main";
import Footer from "./footer";

const Home = () => {

    return (
        <BrowserRouter>
            <ScrollToTop>
                <Header />
                <Routes>
                    <Route exact path='/*' element={<Main />} />
                </Routes>
                <Footer />
            </ScrollToTop>
        </BrowserRouter>
    );
};

export default Home;