import React from "react";

import "../assets/css/main_feature.scss";

import imgPlatform from "../assets/images/img001.jpeg";
import imgEveryStep from "../assets/images/img003.jpeg";
import imgPrice from "../assets/images/img004.jpeg";

const MainFeatures = () => {

    return (
        <div className="main-content-inner-container">
            <div className="main-feature-container main-feature-container-mb">
                <div className="main-feature-image">
                    <img src={imgPlatform} alt="One Platform. All Kinds of Opportunities." />
                </div>
                <div className="main-feature-text">
                    <h4>One Platform. All kinds of Opportunities.</h4>
                    <div className="main-feature-text-block">
                        We're not building just another job board. We're building the WORLD-CLASS hiring platform that you deserve, while addressing key issues regarding intuitive features, frustrating experiences from both the job seeker and employer, and of course COST!
                    </div>
                    <div className="mt-4">
                        <a href={`${process.env.REACT_APP_HIRE_APP_URL}/job-post/`} className="main-feature-join-link">
                            Post a Job &gt;
                        </a>
                    </div>
                </div>
            </div>
            <div className="main-feature-container main-feature-container-mb">
                <div className="main-feature-text">
                    <h4>With You Every Step of the Way</h4>
                    <div className="main-feature-text-block">
                        We're working closely with industry experts, customers, and job seekers to ensure that what we're building is exactly what you need through the entire hiring process.
                    </div>
                    <div className="mt-4">
                        <a href={`${process.env.REACT_APP_HIRE_APP_URL}/job-post/`} className="main-feature-join-link">
                            Post a Job &gt;
                        </a>
                    </div>
                </div>
                <div className="main-feature-image">
                    <img src={imgEveryStep} alt="With You Every Step of the Way" />
                </div>
            </div>
            <div className="main-feature-container main-feature-reverse main-feature-container-mb">
                <div className="main-feature-image">
                    <img src={imgPrice} alt="All for an Incredible Price" />
                </div>
                <div className="main-feature-text">
                    <h4>All for an Incredible Price</h4>
                    <div className="main-feature-text-block">
                        Job posts on AnotherHire™ are $49 for 45 days! We make it easy to budget and manage your job posts and even offer discounts on bundles! And… NO EXPIRATION on purchased bundles, use them as you need them.
                    </div>
                    <div className="mt-4">
                        <a href={`${process.env.REACT_APP_HIRE_APP_URL}/job-post/`} className="main-feature-join-link">
                            Get Started &gt;
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainFeatures;